import React, { PureComponent, ReactNode } from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/layout'
import Section, {Header, Divider} from '../components/section/section'
import Modal from '../components/modal'
import ImageGallery from '../components/imageGallery'
import { WindowLocation } from '@reach/router'

import theme from '../theme'

export interface ImageNode {
  childImageSharp: {
    sizes: {
      aspectRatio: number,
      base64: string,
      sizes: string,
      src: string,
      srcSet: string
    }
  },
  name: string
}

interface GalleryPageProps {
  location: WindowLocation,
  children: ReactNode[],
  data: {
    images: {
      edges: ImageNode[]
    } | null
  }
}

interface GalleryPageState {
  toggledImage: string | undefined
}

export default class Gallery extends PureComponent<GalleryPageProps, GalleryPageState> {
  constructor(props: GalleryPageProps) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)

    this.state = {
      toggledImage: undefined
    }
  }

  toggleImage(event: React.MouseEvent<HTMLImageElement>) : void {
    let imageSrc = null

    if (event.currentTarget && event.currentTarget.childNodes[0].nodeValue !== 'X') {
      const firstNode = event.currentTarget.childNodes[0]
      const secondNode = firstNode.childNodes[0]
      const thirdNode = secondNode.childNodes[2]
      imageSrc = thirdNode.childNodes[1].src
    }

    this.setState({
      toggledImage: imageSrc
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            maxHeight: '90vh', 
            maxWidth: '99vw',
            padding: '40px'
          }}/>
      </Modal>
    ) : null

    return(
      <Layout location={this.props.location}>
        <Section 
          width="100%"
          margin="0"
          flexDirection="column"
          background="whitesmoke">
          <Header>Gallery</Header>
          <Divider themeColor={theme.palette.primary.main}/>
          <ImageGallery 
            images={this.props.data.images !== null ? this.props.data.images.edges : null}
            toggleImage={this.toggleImage} />
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query Images {
    images: allFile( 
      filter: { 
        extension: { 
          regex: "/(jpe?g|png)/"
        }, 
        sourceInstanceName: { 
          eq: "images"
        }, 
        name: { 
          regex: "/[^drums|bass|guitar|compressor|equalizer|reverb|microphone|misc|software|keyboard|console|amp|preamp|logoRed|aboveWaves|jl|bigScoob|brothaLynchHung|cesCru|darreinSafron|e40|garyClarkJr|krizzKaliko|mackenzieNicole|stevieStone|techN9ne|wanyaMorris]/" 
        }
      }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
